import Logger from '../Logger.js';
import BaseEvent from './BaseEvent.js';
import ForwardEvent from './ForwardEvent.js';
import ChangeLocalStreamEvent from './ChangeLocalStreamEvent.js';
import {
  isPresentationStream,
  isCameraStream,
  isVBGStream,
  hasAudio,
  hasVideo
} from './../utils/StreamHelpers.js';

class PodiumEvent extends BaseEvent {
  // eslint-disable-next-line max-statements
  handle(msg) {
    // For dev
    Logger.debug('PodiumEvent::handle', msg);
    const { _session } = this.context;
    if (!_session) {
      throw new Error('Session does not exist');
    }
    const { localStream } = _session;

    if (
      !msg.hasPresenter &&
      isPresentationStream(localStream) &&
      !_session.stopPresentingSet
    ) {
      _session.emit({ type: 'stop_presenting' });
    }

    if (_session && !_session.externalStream) {
      this.scanMessageForLocalStreamOptimization(localStream, msg);
    }

    new ForwardEvent(this.context).handle(msg);
  }

  // Optimization to avoid streaming when not on video podium.
  // First, we need a localStream, then we avoid adjustments when we initially
  // join the room (isSource) and finally if we're not on the podium,
  // but have a local camera stream with video, we change our local stream.
  //
  // We only re-activate the camera in the opposite case if the current
  // localStream was locallyChanged (through this optimization) since we
  // want to avoid situations where a user doesn't expect to be on the video
  // podium and we suddenly turned on their camera.
  //
  // Last case: someone else is presenting (screen share or canvas presentation)
  // we're still on the video podium but not actively presenting.
  // eslint-disable-next-line max-statements
  scanMessageForLocalStreamOptimization(localStream, msg) {
    const { video, isSource, isPresenter, hasPresenter, hasDesktopSources } =
      msg;

    if (!localStream) {
      return;
    }

    if (
      isSource &&
      ((!video && (isCameraStream(localStream) || isVBGStream(localStream))) ||
        (video &&
          !hasVideo(localStream) &&
          localStream.locallyChanged &&
          !hasPresenter))
    ) {
      const options = {
        audio: hasAudio(localStream),
        video: video
      };
      if (localStream.wasScreenAsVideo) {
        options.video = false;
        options.screen = true;
      }
      new ChangeLocalStreamEvent(this.context).handle(options);
    }

    if (video && !isPresenter && hasDesktopSources) {
      new ChangeLocalStreamEvent(this.context).handle({
        audio: hasAudio(localStream),
        video: false
      });
    }
  }
}

export default PodiumEvent;
