/* eslint-disable max-lines */
import EventEmitter from './eventEmitter.js';
import Transport from './Transport.js';
import crlfNormalize from './crlfNormalize.js';
import parseJwtPayload from './parseJwtPayload.js';
import uuid from './uuid.js';

const checkUserAgentOptions = (options = {}) => {
  let failed = '';
  [
    'client_id',
    'conf_id',
    'client_name',
    'sessionDescriptionHandlerFactory',
    'transportOptions'
  ].some(key => {
    if (!options[key]) {
      failed = key;
      return false;
    }
    return true;
  });
  if (!failed) {
    ['auth_token', 'endpoint'].some(key => {
      if (!options.transportOptions[key]) {
        failed = `transportOptions.${key}`;
        return false;
      }
      return true;
    });
  }
  if (failed) {
    throw new Error(`Invalid options - ${failed}`);
  }
};

const slowdown = (fn, ms, ctx) => {
  const stack = [];
  let wait = false;
  const cb = (...args) => {
    if (wait) {
      stack.push(args);
      return;
    }
    wait = true;
    setTimeout(() => {
      wait = false;
      if (stack.length > 0) {
        const nextArgs = stack.shift();
        Reflect.apply(cb, null, nextArgs);
      }
    }, ms);
    Reflect.apply(fn, ctx, args);
  };
  return cb;
};

class UserAgent extends EventEmitter {
  constructor(options) {
    super();
    checkUserAgentOptions(options);
    this.callId = null;
    this.sessionDescriptionHandler = null;
    this.options = options;
    this.initAuthToken();
    this.debouncedHandleSDPUpdate = slowdown(this.handleSdpUpdate, 250, this);
  }

  // eslint-disable-next-line max-statements
  async onMessage(message) {
    const obj = JSON.parse(message);
    const { type, data } = obj;
    this.verifySender(obj);
    if (type === 'call_accepted') {
      this.setCallId(data.call_id);
      await this.sessionDescriptionHandler.setDescription(data.sdp);
      this.emit({ type: 'accepted' });
    } else if (type === 'call_resumed') {
      this.sessionDescriptionHandler.setDescription(data.sdp);
      this.emit({ type: 'resumed' });
    } else if (type === 'call_rejected') {
      this.termination = true;
      this.setCallId(null);
      this.emit({
        type: 'terminated',
        reason: 'reject',
        code: data.reject_code
      });
      this.terminate();
    } else if (type === 'call_terminated') {
      this.termination = true;
      this.setCallId(null);
      this.emit({
        type: 'terminated',
        reason: 'bye',
        code: data.term_code
      });
      this.terminate();
    } else if (type === 'sdp_update') {
      this.debouncedHandleSDPUpdate(data);
    } else {
      data.type = type;
      this.emit({ type: 'message', data });
    }
  }

  initAuthToken() {
    const { options } = this;
    const jwt = parseJwtPayload(options.transportOptions.auth_token);
    if (
      jwt === false ||
      jwt.client_id !== options.client_id ||
      jwt.conf_id !== options.conf_id ||
      !jwt.exp
    ) {
      throw new Error('Invalid auth token');
    }
  }

  setCallId(callId) {
    this.callId = callId;
  }

  verifySender(message) {
    const { options } = this;
    if (
      !(message.from === options.conf_id && message.to === options.client_id)
    ) {
      // throw new Error('Invalid message sender');
      // eslint-disable-next-line no-console
      console.error(new Error('Invalid message sender', message));
    }
  }

  async handleSdpUpdate(data) {
    const { callId } = this;
    const { sdp } = data;
    if (!callId || callId !== data.call_id) {
      throw new Error('Invalid call id');
    }
    this.emit({ type: 'sdp_update', sdp });
    const answerSdp = await this.sessionDescriptionHandler.updateDescription(
      sdp
    );
    if (sdp.type === 'offer' && answerSdp) {
      const desc = {
        type: answerSdp.type,
        sdp: crlfNormalize(answerSdp.sdp)
      };
      this.message('sdp_update', {
        call_id: callId,
        sdp: desc
      });
    }
  }

  message(type = 'message', data = {}) {
    const { options } = this;
    const message = JSON.stringify({
      type,
      msg_id: uuid(),
      from: options.client_id,
      to: options.conf_id,
      data
    });
    this.transport.send(message);
  }

  connect() {
    const { options } = this;
    const transport = new Transport(options.transportOptions);
    this.transport = transport;
    this.termination = false;
    this.emit({ type: 'transportCreated', transport });
    transport.onEvent(event => {
      const { type } = event;
      if (type === 'connected') {
        if (!this.sessionDescriptionHandler) {
          this.sessionDescriptionHandler =
            options.sessionDescriptionHandlerFactory(
              options.sessionDescriptionHandlerFactoryOptions
            );
          this.emit({ type: 'registered' });
        }
      } else if (type === 'message') {
        this.onMessage(event.message);
      } else if (type === 'disconnected') {
        if (!this.termination) {
          this.emit({
            type: 'terminated',
            reason: 'disconnect',
            code: event.was_open ? 0 : -1
          });
        }
      }
    });
    transport.connect();
  }

  // eslint-disable-next-line max-statements
  async call() {
    const { sessionDescriptionHandler, options } = this;
    if (!sessionDescriptionHandler) {
      throw new Error('Invalid sessionDescriptionHandler');
    }
    const description = await sessionDescriptionHandler.getDescription();
    const desc = {
      type: description.type,
      sdp: crlfNormalize(description.sdp)
    };
    this.message('call_start', {
      sdp: desc,
      display_name: options.client_name,
      mute_video: options.mute_video
    });
  }

  resume(authToken) {
    if (!this.callId) {
      throw new Error('Session was already closed');
    }
    this.options.transportOptions.auth_token = authToken;
    try {
      this.initAuthToken();
    } catch (error) {
      this.emit({ type: 'terminated', reason: 'disconnect', code: -1 });
      return;
    }
    const onReconnect = seppEvent => {
      if (seppEvent.type === 'transportCreated') {
        this.offEvent(onReconnect);
        // eslint-disable-next-line max-statements
        this.transport.onEvent(async transportEvent => {
          if (transportEvent.type === 'connected') {
            const { transport, sessionDescriptionHandler } = this;
            if (!this.callId) {
              transport.destroy();
              throw new Error('Session was already closed');
            }
            try {
              const description =
                await sessionDescriptionHandler.getDescription();
              const desc = {
                type: description.type,
                sdp: crlfNormalize(description.sdp)
              };
              this.message('call_resume', {
                call_id: this.callId,
                sdp: desc
              });
            } catch (error) {
              transport.destroy();
              throw new Error('Unable to reconnect');
            }
          }
        });
      }
    };
    this.onEvent(onReconnect);
    this.connect();
  }

  // eslint-disable-next-line max-statements
  terminate() {
    const { sessionDescriptionHandler, transport, callId } = this;
    this.callId = null;
    if (sessionDescriptionHandler) {
      sessionDescriptionHandler.close();
    }
    if (transport) {
      if (transport.isConnected() && callId) {
        this.termination = true;
        this.message('call_terminate', {
          call_id: callId,
          term_code: 0
        });
        this.emit({ type: 'terminated', reason: 'terminate', code: 0 });
      }
      this.transport = null;
      transport.destroy();
    }
  }
}

export default { UserAgent };
