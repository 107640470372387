/* eslint max-lines: off */
import Logger from './Logger.js';

/**
 * eyeson Communications Api
 **/
class ComApi {
  constructor(uri, token) {
    this.uri = uri;
    this.token = token.replace(/\W+/g, '');
    this.cache = { users: [] };
    this.errorCallback = null;
    this._handleError = this._handleError.bind(this);
  }

  _request(path, options = {}) {
    return fetch(new Request(this.uri + path), options).then(response => {
      const contentType = response.headers.get('content-type');

      if (
        contentType &&
        contentType.indexOf('application/json') !== -1 &&
        response.ok
      ) {
        return response.json();
      }

      // not json - e.g. broadcast stop
      if (response.ok) {
        return response.text();
      }

      throw new Error(`ComApiError: ${response.status}`);
    });
  }

  /**
   * NOTE: slightly adjusted from:
   * https://gist.github.com/ghinda/8442a57f22099bdb2e34
   **/
  /* eslint-disable */
  _objectToFormData(obj, form, namespace) {
    let fd = form || new FormData();
    let formKey = null;

    for (let property in obj) {
      if (!obj.hasOwnProperty(property)) {
        return;
      }
      if (namespace) {
        // adjusted, skip "int props" for layout api
        let prop = isNaN(parseInt(property)) ? property : '';
        formKey = namespace + '[' + prop + ']';
      } else {
        formKey = property;
      }
      // if the property is an object, but not a File, use recursivity.
      if (
        typeof obj[property] === 'object' &&
        !(obj[property] instanceof File)
      ) {
        this._objectToFormData(obj[property], fd, property);
      } else {
        // if it's a string or a File object
        fd.append(formKey, obj[property]);
      }
    }

    return fd;
  }
  /* eslint-enable */

  _post(path, data) {
    return this._request(path, {
      method: 'POST',
      body: this._objectToFormData(data)
    });
  }

  _put(path, data) {
    const formData = new FormData();
    /* eslint-disable guard-for-in */
    for (const key in data) {
      formData.append(key, data[key]);
    }
    /* eslint-enable */
    return this._request(path, {
      method: 'PUT',
      body: formData
    });
  }

  _handleError(error) {
    Logger.error('ComApi::handleError', error);
    if (this.errorCallback) {
      this.errorCallback(error);
    }
    return false;
  }

  onError(callback) {
    this.errorCallback = callback;
  }

  /**
   * Fetch room details including the user and credentials from com-api.
   **/
  getRoom(callback) {
    return this._request(`/rooms/${this.token}`)
      .then(callback)
      .catch(error => {
        return callback ? callback({ error: error }) : { error: error };
      });
  }

  /**
   * Terminate meeting immediately for all users
   **/
  terminateMeeting() {
    return this._request(`/rooms/${this.token}`, {
      method: 'DELETE'
    }).catch(this._handleError);
  }

  /**
   * Retrieve user information. Will use cache on multiple requests.
   **/
  getUser(userId, callback) {
    const user = this.cache.users.find(cachedUser => cachedUser.id === userId);

    if (user) {
      callback(user);
      return null;
    }

    return this._request(`/rooms/${this.token}/users/${userId}`)
      .then(apiUser => {
        let users = this.cache.users.filter(
          cachedUser => cachedUser.id !== userId
        );
        let extendedUser = this.addUserAttributes(apiUser, userId, userId);
        users.push(extendedUser);
        this.cache.users = users;
        callback(extendedUser);
      })
      .catch(this._handleError);
  }

  addUserAttributes(user, userId, clientId) {
    let largeAvatarUrl = user.avatar;

    try {
      largeAvatarUrl = `${user.avatar}?size=large`;
    } catch (error) {
      Logger.warn('ComApi::addUserAttributes missing', user);
    }

    return Object.assign(user, {
      id: userId,
      apiId: user.id,
      clientId: clientId,
      // sipId will be removed in future (2023)!
      sipId: clientId,
      largeAvatar: largeAvatarUrl
    });
  }

  /**
   * Remove user from current meeting.
   **/
  kickUser(userId) {
    return this._request(`/rooms/${this.token}/users/${userId}`, {
      method: 'DELETE'
    })
      .then(() => {
        const user = this.cache.users.find(
          cachedUser => cachedUser.id === userId
        );
        if (user) {
          let users = this.cache.users.filter(
            cachedUser => cachedUser.id !== userId
          );
          this.cache.users = users;
        }
      })
      .catch(this._handleError);
  }

  /**
   * Request a guest user from api.
   **/
  /* eslint-disable camelcase */
  requestUser(user, callback) {
    return this._post(`/guests/${this.token}`, {
      name: user.name,
      email: user.email,
      custom_fields: {
        locale: user.locale || 'en'
      }
    })
      .then(callback)
      .catch(error => callback({ error: error }));
  }

  /**
   * Broadcast to a platform.
   **/
  /* eslint-disable camelcase */
  startBroadcast(data, platform) {
    return this._post(`/rooms/${this.token}/broadcasts`, {
      platform: platform,
      player_url: data.playerUrl || '',
      stream_url: data.streamUrl
    });
  }
  /* eslint-enable camelcase */

  /**
   * Broadcast to a platform.
   **/
  /* eslint-disable camelcase */
  publishBroadcast(data, platform) {
    return this._put(`/rooms/${this.token}/broadcasts/${platform}`, {
      player_url: data.playerUrl
    });
  }
  /* eslint-enable camelcase */

  /**
   * Stop a broadcast from a platform.
   **/
  stopBroadcast(platform) {
    return this._request(`/rooms/${this.token}/broadcasts/${platform}`, {
      method: 'DELETE'
    });
  }

  /**
   * Stop all broadcasts.
   **/
  stopAllBroadcasts() {
    return this._request(`/rooms/${this.token}/broadcasts`, {
      method: 'DELETE'
    });
  }

  /**
   * Start a recording.
   **/
  startRecording() {
    return this._request(`/rooms/${this.token}/recording`, {
      method: 'POST'
    });
  }

  /**
   * Stop a recording
   **/
  stopRecording() {
    return this._request(`/rooms/${this.token}/recording`, {
      method: 'DELETE'
    }).catch(this._handleError);
  }

  /**
   * Set layout
   * params object either as { layout: 'auto' } or { users: [ id1, id2 ...] }
   **/
  setLayout(params) {
    return this._post(`/rooms/${this.token}/layout`, params).catch(
      this._handleError
    );
  }

  /**
   * Set layer
   *
   * params object: {
   *   insert: {
   *     icon:    user['avatar'],
   *     title:   "#{user['name']}:",
   *     content: question.truncate(280)
   *   }
   * }
   *
   **/
  setLayer(params) {
    return this._post(`/rooms/${this.token}/layers`, params).catch(
      this._handleError
    );
  }

  /**
   * Clear front most layer
   **/
  clearFrontLayer() {
    return this._request(`/rooms/${this.token}/layers/1`, {
      method: 'DELETE'
    }).catch(this._handleError);
  }

  /**
   * Take a snapshot of the current podium
   **/
  takeSnapshot() {
    return this._request(`/rooms/${this.token}/snapshot`, {
      method: 'POST'
    }).catch(this._handleError);
  }

  /**
   * Start a playback
   **/
  startPlayback(playback) {
    return this._post(`/rooms/${this.token}/playbacks`, {
      playback: playback
    }).catch(this._handleError);
  }

  /**
   * Stop a playback identified by play_id
   **/
  stopPlayback(playback) {
    return this._request(`/rooms/${this.token}/playbacks/${playback.play_id}`, {
      method: 'DELETE'
    }).catch(this._handleError);
  }

  /**
   * Lock meeting to freeze current participants
   */
  lockMeeting() {
    return this._request(`/rooms/${this.token}/lock`, {
      method: 'POST'
    }).catch(this._handleError);
  }
}

export default ComApi;
