import Logger from './Logger.js';

const updateInterval = 100;

/**
 * Sound Meter that generates a number correlated to audio volume. The meter
 * itself displays nothing, but it makes the instantaneous and time-decaying
 * volumes available for inspection. It also reports on the fraction of samples
 * that were at or near the top of the measurement range.
 **/
class SoundMeter {
  // eslint-disable-next-line max-statements
  constructor() {
    this.level = 0;
    this.instant = 0;
    this.silenceDuration = 0;
    this.intervalCounter = 0;
    this.track = null;
    this.context = null;
    this.source = null;
    this.analyser = null;
    this.volumes = null;
    this.listener = [];
    this.timer = null;
    this.errorTimer = null;
    this.boundOnTrackEnded = this.onTrackEnded.bind(this);
    this.initError = false;
    this.init();
  }

  /**
   * Initialize audio context and prepare script processor to read input
   * channel data.
   **/
  init() {
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    if (typeof AudioContext === 'undefined') {
      Logger.error(
        'AudioContext is not available. Probably the current' +
          ' user agent does not support this feature. Use the feature detector' +
          ' to hide not supported elements. Any call on onUpdate will not' +
          ' send values.'
      );
      return;
    }
    this.context = new AudioContext();

    this.context.onstatechange = ({ target }) => {
      if (target.state === 'suspended') {
        Logger.error('SoundMeter::init AudioContext: ', target.state);
      }
    };
  }

  /**
   * Connect to a media stream.
   **/
  // eslint-disable-next-line max-statements
  connectToSource(stream) {
    if (!this.context) {
      return this;
    }
    if (!stream || stream.getAudioTracks().length < 1) {
      this.initError = true;
      this.onTrackEnded();
      return this;
    }
    Logger.debug('SoundMeter::connectToSource', stream);
    [this.track] = stream.getAudioTracks();
    if (this.track.readyState !== 'live') {
      this.initError = true;
      this.onTrackEnded();
      return this;
    }
    this.track.addEventListener('ended', this.boundOnTrackEnded);
    this.source = this.context.createMediaStreamSource(stream);
    const analyser = this.context.createAnalyser();
    analyser.fftSize = 512;
    analyser.minDecibels = -127;
    analyser.maxDecibels = 0;
    analyser.smoothingTimeConstant = 0.5;
    this.source.connect(analyser);
    this.analyser = analyser;
    this.volumes = new Uint8Array(analyser.frequencyBinCount);
    if (!this.running && this.listener.length > 0) {
      this.running = true;
      this.timer = setInterval(() => this.analyse(), updateInterval);
    }
    return this;
  }

  /**
   * Read audio level. Avoids triggering a re-render of the device-dialog
   * when the change is below 1% which wouldn't be noticable anyways.
   *
   * NOTE: When an USB Microphone is abruptly disconnected. The
   * intervalCounter reacts: when switching to a different mic, it seems to
   * take a bit to not return 0.00 for the audio level.
   **/
  // eslint-disable-next-line max-statements
  analyse() {
    const { analyser, volumes, level } = this;
    if (!analyser) {
      return;
    }
    analyser.getByteFrequencyData(volumes);
    let volumeSum = 0;
    for (const volume of volumes) {
      volumeSum += volume;
    }
    const averageVolume = volumeSum / volumes.length;
    const instant = Math.min(Math.round((averageVolume * 100) / 127), 100);

    // Handle microphone disconnect
    if (level === 0 && instant === 0 && this.intervalCounter === 10) {
      this.silenceDuration++;
      if (this.silenceDuration > 30 && !this.errorTimer) {
        this.errorTimer = setTimeout(() => {
          this.emit({ warning: 'MicrophoneSilenceWarning' });
          this.resetSilenceCounter();
        }, 1000);
      }
    }

    if (level !== instant) {
      // Clear a MicrophoneError once we get a signal, intervalCounter might be
      // too low sometimes.
      if (this.silenceDuration > 0) {
        this.resetSilenceCounter();
      }
      const diff = Math.abs(level - instant);
      if (diff >= 1) {
        this.level = instant;
        this.emit({ value: instant });
      }
    }
    if (this.intervalCounter < 10) {
      this.intervalCounter++;
    }
  }

  resetSilenceCounter() {
    clearTimeout(this.errorTimer);
    this.errorTimer = null;
    this.silenceDuration = 0;
  }

  /**
   * Stop read interval and disconnect from stream.
   **/
  // eslint-disable-next-line max-statements
  stop() {
    this.offUpdate();
    if (this.track) {
      this.track.removeEventListener('ended', this.boundOnTrackEnded);
      this.track = null;
    }
    if (this.analyser) {
      this.analyser.disconnect();
      this.analyser = null;
    }
    if (this.source) {
      this.source.disconnect();
      this.source = null;
    }
    this.volumes = null;
    if (this.context && this.context.stop && this.context.state !== 'closed') {
      this.context.onstatechange = null;
      this.context.close();
      this.context = null;
    }
  }

  onUpdate(listener) {
    this.listener.push(listener);
    if (this.initError) {
      this.onTrackEnded();
      return;
    }
    if (!this.running && this.context && this.track) {
      this.running = true;
      this.timer = setInterval(() => this.analyse(), updateInterval);
    }
  }

  offUpdate() {
    clearTimeout(this.errorTimer);
    this.listener.length = 0;
    if (this.running) {
      clearInterval(this.timer);
      this.running = false;
    }
  }

  onTrackEnded() {
    this.emit({ error: 'EyesonMicrophoneError' });
    this.stop();
  }

  emit(message) {
    this.listener.forEach(fn => fn(message));
  }
}

export default SoundMeter;
