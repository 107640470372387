import BaseEvent from './BaseEvent.js';

class SendChatEvent extends BaseEvent {
  handle(msg) {
    if (!this.context._session) {
      throw new Error('Session does not exist');
    }
    this.context._session.send({
      type: 'chat',
      content: msg.content
    });
  }
}

export default SendChatEvent;
