/* eslint-disable max-lines */
/* eslint-disable max-statements */
import FeatureDetector from './FeatureDetector.js';
import Logger from './Logger.js';
import LocalStorage from './LocalStorage.js';
import throttledAnimationFrame from './utils/throttledAnimationFrame.js';
import importScript from './utils/importScript.js';
import { stopStream, stopTrack, getVbgTracks } from './utils/StreamHelpers.js';
import cacheStorage from './utils/cacheStorage.js';
import canvasBlur from './utils/canvasBlur.js';

window.exports = window.exports || {};

const OffscreenCanvasSupport =
  typeof window.OffscreenCanvas === 'function' &&
  (() => {
    try {
      new OffscreenCanvas(1, 1).getContext('2d');
      return true;
      // eslint-disable-next-line no-empty
    } catch (error) {}
    return false;
  })();
const OffscreenCanvasBlurSupport =
  OffscreenCanvasSupport &&
  Boolean(window.OffscreenCanvasRenderingContext2D) &&
  'filter' in OffscreenCanvasRenderingContext2D.prototype;
const ImageBitmapSupport = typeof window.createImageBitmap === 'function';
const TrackProcessorSupport =
  typeof window.MediaStreamTrackProcessor === 'function';
const SIMDSupport =
  window.WebAssembly &&
  WebAssembly.validate(
    new Uint8Array([
      0, 97, 115, 109, 1, 0, 0, 0, 1, 4, 1, 96, 0, 0, 3, 2, 1, 0, 10, 9, 1, 7,
      0, 65, 0, 253, 15, 26, 11
    ])
  );
const EventTargetConstructorSupport = (() => {
  try {
    // eslint-disable-next-line no-new
    new EventTarget();
    return true;
    // eslint-disable-next-line no-empty
  } catch (error) {}
  return false;
})();

const _max = Math.max;

const _frameRate = 20;
const _stateChange = EventTargetConstructorSupport
  ? new EventTarget()
  : document.createElement('i');
const _segmentationModel = {
  id: 'selfie_landscape',
  name: 'selfie_segmentation_landscape',
  width: 256,
  height: 144,
  pixelCount: 36864
};

let _tflite = null;
let _tfliteReady = false;
let _tfliteLoading = false;
let _inputMemoryOffset = null;
let _outputMemoryOffset = null;

let _localImageFile = null;
let _localImageFileBackup = null;

const initiateTFLite = async () => {
  if (_tflite) {
    return;
  }
  _tfliteLoading = true;
  _stateChange.dispatchEvent(new Event('change'));
  const loading = await Promise.all([
    SIMDSupport
      ? importScript('vendor/tflite/tflite-simd.js')
      : importScript('vendor/tflite/tflite.js'),
    fetch(`vendor/tflite/models/${_segmentationModel.name}.tflite`)
  ]);
  _tflite = await window.exports[
    SIMDSupport ? 'createTFLiteSIMDModule' : 'createTFLiteModule'
  ]();
  const model = await loading[1].arrayBuffer();
  const modelBufferOffset = _tflite._getModelBufferMemoryOffset();
  _tflite.HEAPU8.set(new Uint8Array(model), modelBufferOffset);
  _tflite._loadModel(model.byteLength);
  _inputMemoryOffset = _tflite._getInputMemoryOffset() / 4;
  _outputMemoryOffset = _tflite._getOutputMemoryOffset() / 4;
  _tfliteLoading = false;
  _tfliteReady = true;
  _stateChange.dispatchEvent(new Event('change'));
};

const createOffscreenCanvas = (width, height, options = {}, type) => {
  const result = {
    canvas: null,
    ctx: null
  };
  const extra = type === 'blur' && OffscreenCanvasBlurSupport;
  if (OffscreenCanvasSupport && extra) {
    result.canvas = new OffscreenCanvas(width, height);
    result.ctx = result.canvas.getContext('2d', options);
    return result;
  }
  result.canvas = document.createElement('canvas');
  result.canvas.width = width;
  result.canvas.height = height;
  result.ctx = result.canvas.getContext(
    '2d',
    Object.assign({ desynchronized: true }, options)
  );
  return result;
};

(async () => {
  const virtualBackgroundType = LocalStorage.load('virtualBackgroundType');
  if (virtualBackgroundType === 'image:blob') {
    const url = await cacheStorage.loadBlobURL('/virtualBackgroundLocalImage');
    if (url) {
      _localImageFile = url;
    }
  }
})();

const loadImage = (url, instance) => {
  return new Promise(resolve => {
    if (instance && instance.state === 'ready') {
      instance.emitLoading(true);
    }
    if (url === 'blob' && !_localImageFile) {
      resolve(null);
      return;
    }
    const img = new Image();
    img.onerror = () => resolve(null);
    img.onload = () => resolve(img);
    if (/^([\w]+:)?\/\//.test(url) && url.indexOf(location.host) === -1) {
      img.crossOrigin = 'anonymous';
    }
    img.src = url === 'blob' && _localImageFile ? _localImageFile : url;
  }).then(img => {
    if (instance && instance.state === 'ready') {
      instance.emitLoading(false);
    }
    return img;
  });
};

const checkImageLoad = url => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onerror = () => reject(new TypeError('Invalid image file'));
    img.onload = () => resolve();
    if (/^([\w]+:)?\/\//.test(url) && url.indexOf(location.host) === -1) {
      img.crossOrigin = 'anonymous';
    }
    img.src = url;
  });
};

const loadImageFile = (() => {
  let input = null;
  return callbackFN => {
    if (!input) {
      input = Object.assign(document.createElement('input'), {
        type: 'file',
        accept: 'image/*'
      });
    }
    input.onchange = ({ target: { files } }) => {
      if (files.length > 0) {
        const url = URL.createObjectURL(files[0]);
        checkImageLoad(url).then(() => {
          if (_localImageFile && _localImageFile !== _localImageFileBackup) {
            URL.revokeObjectURL(_localImageFile);
          }
          _localImageFile = url;
          callbackFN();
        }, callbackFN);
      }
      input.onchange = null;
      input.value = '';
    };
    input.click();
  };
})();

const createImageCanvas = (image, width, height) => {
  const hRatio = width / image.width;
  const vRatio = height / image.height;
  const ratio = _max(hRatio, vRatio);
  const imgWidth = image.width * ratio;
  const imgHeight = image.height * ratio;
  const centerShiftX = (width - imgWidth) / 2;
  const centerShiftY = (height - imgHeight) / 2;
  const canvas = createOffscreenCanvas(width, height, { alpha: false });
  canvas.ctx.drawImage(
    image,
    0,
    0,
    image.width,
    image.height,
    centerShiftX,
    centerShiftY,
    imgWidth,
    imgHeight
  );
  return canvas.canvas;
};

const createGeneraliCanvas = (image, width, height) => {
  const canvas = createOffscreenCanvas(width, height, { alpha: false });
  const { ctx } = canvas;
  ctx.fillStyle = '#ffffff';
  ctx.fillRect(0, 0, width, height);
  ctx.drawImage(image, 15, 15);
  return canvas.canvas;
};

const createGradientCanvas = (width, height, options) => {
  const canvas = createOffscreenCanvas(width, height, { alpha: false });
  const { ctx } = canvas;
  const gradient = ctx.createLinearGradient(
    options.startX,
    options.startY,
    options.endX,
    options.endY
  );
  gradient.addColorStop(0, options.color1);
  gradient.addColorStop(1, options.color2);
  ctx.fillStyle = gradient;
  ctx.fillRect(0, 0, width, height);
  return canvas.canvas;
};

const createBlurCanvas = (instance, width, height, blur) => {
  const length = blur * 2;
  const fullLength = length * 2;
  const bigWidth = width + fullLength;
  const bigHeight = height + fullLength;
  instance.blurCanvas = createOffscreenCanvas(
    bigWidth,
    bigHeight,
    {
      alpha: false
    },
    'blur'
  );
  instance.imageCanvas = createOffscreenCanvas(bigWidth, bigHeight, {
    alpha: false
  });
  instance.blurCanvas.ctx.filter = `blur(${blur}px)`;
};

const _allowedTypesCheck =
  /^(off|eyeson|generali:.*|color:.+|image:.+|blur:\d+)/;

const setState = () => {
  if (_tfliteReady) {
    return 'ready';
  }
  if (_tfliteLoading) {
    return 'initialize';
  }
  return '';
};

class VirtualBackgroundMixer {
  constructor(name = 'global') {
    Logger.info('VirtualBackgroundMixer::constructor', name);
    this.name = name;
    this.canvas = null;
    this.ctx = null;
    this.originalStream = null;
    this.outStream = null;
    this.video = null;
    this.reader = null;
    this.size = { width: 0, height: 0 };
    this.segmentationMaskCanvas = null;
    this.segmentationMaskCtx = null;
    this.segmentationMask = null;
    this.videoCanvas = null;
    this.videoCtx = null;
    this.playPromise = null;
    this.abort = false;
    this.paused = false;
    this.raf = throttledAnimationFrame(this.drawVideo.bind(this), 20);
    this.backgroundOld = null;
    this.backgroundType = null;
    this.backgroundValue = null;
    this.blurInit = false;
    this.blurCanvas = null;
    this.canvasBlur = canvasBlur();
    this.imageCanvas = null;
    this.state = setState();
    this.loadingListener = [];
    this.handleStateChange = () => {
      const state = setState();
      this.state = state;
      this.emitLoading(state === 'initialize');
    };
    _stateChange.addEventListener('change', this.handleStateChange);
  }

  static isTypeAllowed(type) {
    return _allowedTypesCheck.test(type);
  }

  static async checkExternalImage(type) {
    if (/^image:([\w]+:)?\/\//i.test(type)) {
      const url = type.substring(6);
      await checkImageLoad(url);
    }
  }

  static loadLocalImage(callbackFN) {
    loadImageFile(callbackFN);
  }

  static getImageBlobOrFallback(isBlobAvailable = true) {
    if (_localImageFile && isBlobAvailable) {
      return 'image:blob';
    }
    return 'blur:8';
  }

  storeLocalImageFile() {
    if (_localImageFile) {
      _localImageFileBackup = _localImageFile;
    }
  }

  resetLocalImageFile() {
    if (_localImageFileBackup) {
      if (_localImageFile && _localImageFile !== _localImageFileBackup) {
        URL.revokeObjectURL(_localImageFile);
      }
      _localImageFile = _localImageFileBackup;
      _localImageFileBackup = null;
    }
  }

  saveLocalImageFile() {
    if (_localImageFileBackup) {
      if (_localImageFile && _localImageFile !== _localImageFileBackup) {
        URL.revokeObjectURL(_localImageFileBackup);
      }
      _localImageFileBackup = null;
    }
  }

  updateCache(type) {
    if (type === 'image:blob') {
      if (_localImageFile) {
        cacheStorage.storeBlobURL(
          _localImageFile,
          '/virtualBackgroundLocalImage'
        );
      }
    } else {
      cacheStorage.deleteBlob('/virtualBackgroundLocalImage');
    }
  }

  onLoading(listener) {
    this.loadingListener.push(listener);
  }

  offLoading(listener) {
    this.loadingListener = this.loadingListener.filter(fn => fn !== listener);
  }

  emitLoading(loading) {
    this.loadingListener.forEach(listener => listener(loading));
  }

  initiateStream(userMediaStream) {
    Logger.info('VirtualBackgroundMixer::initiate', this.name);
    initiateTFLite();
    const videoTracks = userMediaStream.getVideoTracks();
    if (videoTracks.length === 0) {
      Logger.warn(
        'VirtualBackgroundMixer::initiate',
        this.name,
        'missing track'
      );
      return userMediaStream;
    }
    const [videoTrack] = videoTracks;
    const trackSettings = videoTrack.getSettings();
    if (
      videoTrack.readyState !== 'live' ||
      !trackSettings.width ||
      !trackSettings.height
    ) {
      Logger.warn(
        'VirtualBackgroundMixer::initiate',
        this.name,
        '0 width or height, or not ready',
        videoTrack.readyState,
        trackSettings.width,
        trackSettings.height
      );
      return userMediaStream;
    }
    if (this.originalStream) {
      Logger.warn(
        'VirtualBackgroundMixer::initiate',
        this.name,
        'existing originalStream!',
        this.originalStream.id,
        userMediaStream.id
      );
      if (this.originalStream.id !== userMediaStream.id) {
        this.stopOriginalStream();
      }
      this.terminate();
    }
    this.originalStream = userMediaStream;
    this.canvas = document.createElement('canvas');
    this.canvas.id = 'eyeson-vbg-stream';
    this.ctx = this.canvas.getContext('2d', { desynchronized: true });
    this.ctx.imageSmoothingEnabled = false;
    this.size.width = trackSettings.width;
    this.size.height = trackSettings.height;
    this.canvas.width = trackSettings.width;
    this.canvas.height = trackSettings.height;
    const canvasStream = this.canvas.captureStream(_frameRate);
    const [canvasTrack] = canvasStream.getVideoTracks();
    if (!canvasTrack.canvas) {
      canvasTrack.type = 'canvas-track';
      canvasTrack.canvas = this.canvas;
    }
    const settings = {};
    if (typeof canvasTrack.getSettings === 'function') {
      Object.assign(settings, canvasTrack.getSettings());
    }
    Object.assign(settings, trackSettings);
    canvasTrack.getSettings = () => settings;
    canvasTrack.srcLabel = videoTrack.label;
    canvasTrack.srcDeviceId = trackSettings.deviceId;
    this.outStream = new MediaStream([canvasTrack]);
    userMediaStream
      .getAudioTracks()
      .forEach(track => this.outStream.addTrack(track));
    this.start();
    return this.outStream;
  }

  start() {
    Logger.info('VirtualBackgroundMixer::start', this.name);
    let canvas = null;
    let video = null;
    const { width, height } = this.size;
    if (!width || !height) {
      Logger.warn(
        'VirtualBackgroundMixer::start 0 width or height',
        this.name,
        width,
        height
      );
      return;
    }
    if (TrackProcessorSupport) {
      try {
        const [track] = this.originalStream.getVideoTracks();
        // eslint-disable-next-line no-undef
        const processor = new MediaStreamTrackProcessor(track);
        this.reader = processor.readable.getReader();
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }
    if (!this.reader) {
      video = document.createElement('video');
      video.playsInline = true;
      video.muted = true;
      video.width = width;
      video.height = height;
      video.srcObject = this.originalStream;
      this.video = video;
    }
    this.segmentationMask = new ImageData(
      _segmentationModel.width,
      _segmentationModel.height
    );
    canvas = createOffscreenCanvas(
      _segmentationModel.width,
      _segmentationModel.height,
      { willReadFrequently: true }
    );
    this.segmentationMaskCanvas = canvas.canvas;
    this.segmentationMaskCtx = canvas.ctx;
    this.segmentationMaskCtx.imageSmoothingEnabled = false;
    if (video && !ImageBitmapSupport) {
      canvas = createOffscreenCanvas(width, height);
      this.videoCanvas = canvas.canvas;
      this.videoCtx = canvas.ctx;
    }
    this.abort = false;
    this.paused = false;
    this.blurInit = false;
    this.canvasBlur.reset();
    this.initBackground();
    if (this.reader) {
      Logger.info(
        'VirtualBackgroundMixer::start::videoTrackProcessor',
        this.name
      );
      this.raf.requestAnimationFrame();
    } else {
      video.onloadeddata = () => {
        Logger.info(
          'VirtualBackgroundMixer::start::video.loadeddata',
          this.name
        );
        this.raf.requestAnimationFrame();
      };
      this.playPromise = video.play();
      this.playPromise.catch(error => {
        Logger.error(
          'VirtualBackgroundMixer::start::video.play',
          this.name,
          error
        );
      });
    }
  }

  // eslint-disable-next-line complexity
  async drawVideo() {
    let frame = null;
    if (this.abort) {
      return;
    }
    const {
      ctx,
      size,
      paused,
      segmentationMask,
      segmentationMaskCtx,
      segmentationMaskCanvas
    } = this;
    if (this.reader) {
      try {
        const { value } = await this.reader.read();
        if (value) {
          value.width = value.displayWidth;
          value.height = value.displayHeight;
          frame = value;
        }
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }
    if (!_tfliteReady || paused) {
      ctx.drawImage(frame || this.video, 0, 0);
    } else {
      if (!frame) {
        if (ImageBitmapSupport) {
          try {
            frame = await createImageBitmap(this.video);
            // eslint-disable-next-line no-empty
          } catch (error) {}
        } else {
          this.videoCtx.drawImage(this.video, 0, 0);
          frame = this.videoCanvas;
        }
      }
      if (this.abort) {
        return;
      }
      if (frame && frame.width > 0 && frame.height > 0) {
        segmentationMaskCtx.drawImage(
          frame,
          0,
          0,
          frame.width,
          frame.height,
          0,
          0,
          _segmentationModel.width,
          _segmentationModel.height
        );
        const imageData = segmentationMaskCtx.getImageData(
          0,
          0,
          _segmentationModel.width,
          _segmentationModel.height
        );
        const { data } = imageData;
        const heap = _tflite.HEAPF32;
        const maskData = segmentationMask.data;
        const { pixelCount } = _segmentationModel;
        for (
          let heapIndex = 0, imgIndex = 0, index = 0, indexId = 0;
          index < pixelCount;
          index++
        ) {
          indexId = index * 3;
          heapIndex = _inputMemoryOffset + indexId;
          imgIndex = index * 4;
          heap[heapIndex] = data[imgIndex] / 255;
          heap[heapIndex + 1] = data[imgIndex + 1] / 255;
          heap[heapIndex + 2] = data[imgIndex + 2] / 255;
        }
        _tflite._runInference();
        for (
          let index = 0, indexOut = 0, person = 0.0;
          index < pixelCount;
          index++
        ) {
          person = heap[_outputMemoryOffset + index];
          // Sets only the alpha component of each pixel
          indexOut = index * 4;
          maskData[indexOut + 3] = 255 * person;
        }
        segmentationMaskCtx.putImageData(segmentationMask, 0, 0);
        ctx.globalCompositeOperation = 'copy';
        ctx.filter = 'blur(2px)';
        ctx.drawImage(
          segmentationMaskCanvas,
          0,
          0,
          _segmentationModel.width,
          _segmentationModel.height,
          0,
          0,
          size.width,
          size.height
        );
        ctx.globalCompositeOperation = 'source-in';
        ctx.filter = 'none';
        ctx.drawImage(frame, 0, 0);
        if (this.backgroundType) {
          this.drawBackground(frame);
        }
      }
    }
    if (!paused && this.paused) {
      ctx.filter = 'none';
      ctx.globalCompositeOperation = 'source-over';
    }
    if (frame && typeof frame.close === 'function') {
      frame.close();
    }
    if (!this.abort) {
      this.raf.requestAnimationFrame();
    }
  }

  suspend() {
    const { ctx } = this;
    this.paused = true;
    if (ctx) {
      ctx.filter = 'none';
      ctx.globalCompositeOperation = 'source-over';
    }
  }

  resume() {
    this.paused = false;
  }

  drawBackground(frame) {
    const {
      backgroundType,
      ctx,
      blurCanvas,
      imageCanvas,
      backgroundValue,
      size
    } = this;
    if (!backgroundType || !ctx) {
      return;
    }
    ctx.globalCompositeOperation = 'destination-over';
    if (backgroundType === 'blur') {
      if (FeatureDetector.canvasBlurSupport()) {
        const length = Number(backgroundValue) * 2;
        const fullLength = length * 2;
        if (!this.blurInit) {
          imageCanvas.ctx.drawImage(
            frame,
            0,
            0,
            size.width,
            size.height,
            0,
            0,
            size.width + fullLength,
            size.height + fullLength
          );
          this.blurInit = true;
        }
        imageCanvas.ctx.drawImage(
          frame,
          0,
          0,
          size.width,
          size.height,
          length,
          length,
          size.width,
          size.height
        );
        blurCanvas.ctx.drawImage(imageCanvas.canvas, 0, 0);
        ctx.drawImage(
          blurCanvas.canvas,
          length,
          length,
          size.width,
          size.height,
          0,
          0,
          size.width,
          size.height
        );
      } else {
        const blurred = this.canvasBlur.run(frame, backgroundValue);
        ctx.drawImage(blurred, 0, 0);
      }
    } else if (backgroundType === 'color') {
      ctx.fillStyle = backgroundValue;
      ctx.fillRect(0, 0, size.width, size.height);
    } else if (imageCanvas) {
      ctx.drawImage(imageCanvas, 0, 0);
    } else {
      ctx.drawImage(frame, 0, 0);
    }
  }

  async initBackground() {
    const { backgroundType, backgroundValue } = this;
    const { width, height } = this.size;
    let done = false;
    if (!width || !height) {
      return;
    }
    if (backgroundType === 'eyeson') {
      this.imageCanvas = createGradientCanvas(width, height, {
        startX: 0,
        startY: 0,
        endX: width,
        endY: 50,
        color1: '#ff7676',
        color2: '#9e206c'
      });
      done = true;
    } else if (backgroundType === 'generali') {
      this.setBackgroundFallback();
      const image = await loadImage(backgroundValue, this);
      if (image) {
        this.backgroundType = 'image';
        this.backgroundValue = backgroundValue;
        this.imageCanvas = createGeneraliCanvas(image, width, height);
        done = true;
      }
    } else if (backgroundType === 'image') {
      this.setBackgroundFallback();
      const image = await loadImage(backgroundValue, this);
      if (image) {
        this.backgroundType = 'image';
        this.backgroundValue = backgroundValue;
        this.imageCanvas = createImageCanvas(image, width, height);
        done = true;
      }
    } else if (backgroundType === 'blur') {
      this.blurInit = false;
      createBlurCanvas(this, width, height, Number(backgroundValue));
      this.canvasBlur.reset();
      done = true;
    } else {
      this.imageCanvas = null;
      done = true;
    }
    if (done) {
      this.backgroundOld = {
        type: backgroundType,
        value: backgroundValue
      };
    }
  }

  setBackgroundFallback() {
    const { backgroundOld } = this;
    if (backgroundOld) {
      this.backgroundType = backgroundOld.type;
      this.backgroundValue = backgroundOld.value;
    } else {
      if (!this.imageCanvas) {
        const { width, height } = this.size;
        this.blurInit = false;
        createBlurCanvas(this, width, height, 8);
      }
      this.backgroundType = 'blur';
      this.backgroundValue = '8';
    }
  }

  changeBackground(type) {
    Logger.info('VirtualBackgroundMixer::changeBackground', this.name, type);
    if (typeof type !== 'string') {
      return;
    }
    const colon = type.indexOf(':');
    if (colon === -1) {
      this.backgroundType = type === 'off' ? null : type;
      this.backgroundValue = null;
    } else {
      this.backgroundType = type.substring(0, colon);
      this.backgroundValue = type.substring(colon + 1);
    }
    if (!this.abort) {
      this.initBackground();
    }
  }

  stop() {
    Logger.info('VirtualBackgroundMixer::stop', this.name);
    this.abort = true;
    if (this.raf) {
      this.raf.cancelAnimationFrame();
    }
    if (this.video) {
      const { video, playPromise } = this;
      if (playPromise && video) {
        playPromise.then(() => video.pause());
      }
      this.video.onloadeddata = null;
      this.video = null;
      this.playPromise = null;
    }
    if (this.reader) {
      this.reader = null;
    }
    this.segmentationMaskCanvas = null;
    this.segmentationMaskCtx = null;
    this.segmentationMask = null;
    this.videoCanvas = null;
    this.videoCtx = null;
    this.backgroundOld = null;
    this.imageCanvas = null;
    this.blurCanvas = null;
    this.canvasBlur.reset();
  }

  stopOriginalStream() {
    Logger.info(
      'VirtualBackgroundMixer::stopOriginalStream',
      this.name,
      this.originalStream
    );
    this.stop();
    stopStream(this.originalStream);
  }

  terminate() {
    Logger.info('VirtualBackgroundMixer::terminate', this.name);
    this.stop();
    if (this.outStream) {
      getVbgTracks(this.outStream).forEach(track => {
        stopTrack(track);
        Logger.info(
          'VirtualBackgroundMixer::terminate stop track',
          this.name,
          track
        );
      });
    }
    this.originalStream = null;
    this.outStream = null;
    this.canvas = null;
    this.ctx = null;
    this.video = null;
  }

  destroy() {
    Logger.info('VirtualBackgroundMixer::destroy', this.name);
    _stateChange.removeEventListener('change', this.handleStateChange);
    this.loadingListener.length = 0;
    this.canvasBlur = null;
  }
}

export default VirtualBackgroundMixer;
