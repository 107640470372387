/* eslint-disable max-lines */
import Logger from './Logger.js';
import eyesonOptions from './options.js';
import LocalStorage from './LocalStorage.js';
import { stopStream } from './utils/StreamHelpers.js';
import VirtualBackgroundMixer from './VirtualBackgroundMixer.js';
import FeatureDetector from './FeatureDetector.js';

const changedDevices = (listA, listB) => {
  if (listA.length !== listB.length) {
    return true;
  }
  for (
    let deviceA = null, found = false, { length } = listA, index = 0;
    index < length;
    index++
  ) {
    deviceA = listA[index];
    found = listB.find(deviceB => {
      return (
        deviceA.deviceId === deviceB.deviceId &&
        deviceA.groupId === deviceB.groupId &&
        deviceA.label === deviceB.label &&
        deviceA.kind === deviceB.kind
      );
    });
    if (!found) {
      return true;
    }
  }
  return false;
};

/**
 * eyeson Device Manager used to handle cameras, microphones and speakers.
 **/
class DeviceManager {
  /* eslint-disable max-statements */
  constructor(options) {
    this.sinkId = 'default';
    this.options = options || { audio: true, video: true, eco: false };
    this.stream = null;
    this.cameras = [];
    this.listeners = [];
    this.speakers = [];
    this.microphones = [];
    this.constraints = {};
    this.terminationInProgress = false;
    this.virtualBackground = new VirtualBackgroundMixer('DeviceManager');
    this.virtualBackgroundType = 'off';

    DeviceManager.getSinkId().then(sinkId => (this.sinkId = sinkId));

    this.setStream = this.setStream.bind(this);
    this.handleError = this.handleError.bind(this);
    this.verifyStream = this.verifyStream.bind(this);
    this.storeConstraints = this.storeConstraints.bind(this);
    this.adjustAudioTrack = this.adjustAudioTrack.bind(this);
    this.initiateVirtualBackground = this.initiateVirtualBackground.bind(this);
  }
  /* eslint-enable max-statements */

  static getDevices() {
    if (!('mediaDevices' in navigator)) {
      return Promise.resolve([]);
    }
    return navigator.mediaDevices.enumerateDevices().then(devices => {
      // hack for safari ios and desktop because they hide existing devices to avoid fingerprinting: https://webkit.org/blog/7763/a-closer-look-into-webrtc/
      if (
        devices.length === 2 &&
        devices.every(
          device => device.kind === 'audioinput' && device.deviceId === ''
        )
      ) {
        return [
          { deviceId: '', groupId: '', kind: 'audioinput', label: '' },
          { deviceId: '', groupId: '', kind: 'videoinput', label: '' }
        ];
      }
      return devices;
    });
  }

  /**
   * Get constraints for specified options { audio: true, video: true }
   * If a specific device is chosen it has to be stored in localStorage or will
   * be ignored.
   **/
  static getConstraints(optionsParam) {
    const options = optionsParam || { audio: true, video: true };
    return DeviceManager.getDevices()
      .then(devices => {
        return DeviceManager.determineConstraintsForDevices(devices, options);
      })
      .catch(error => {
        Logger.error('DeviceManager::getConstraints ', error);
      });
  }

  /* eslint-disable max-statements, complexity */
  static determineConstraintsForDevices(devices, options) {
    const constraints = LocalStorage.load('mediaConstraints', options);
    const stereo = FeatureDetector.canStereo() && !options.eco;
    const audioConstraints = { channelCount: stereo ? 2 : 1 };

    if (!('video' in constraints)) {
      constraints.video = true;
    }
    if (!('audio' in constraints)) {
      constraints.audio = audioConstraints;
    }

    if (constraints.video.deviceId) {
      const videoDeviceId = constraints.video.deviceId.exact;
      if (!devices.find(device => device.deviceId === videoDeviceId)) {
        constraints.video = options.video;
      }
    }
    if (constraints.audio.deviceId) {
      const audioDeviceId = constraints.audio.deviceId.exact;
      if (!devices.find(device => device.deviceId === audioDeviceId)) {
        constraints.audio = options.audio ? audioConstraints : false;
      }
    }

    if (options.video === false && !options.eco) {
      constraints.video = false;
    }
    if (options.video === true && constraints.video === false) {
      constraints.video = true;
    }
    if (
      (options.audio === true && constraints.audio === false) ||
      constraints.audio === true
    ) {
      constraints.audio = audioConstraints;
    }
    if (
      constraints.audio &&
      constraints.audio.channelCount !== audioConstraints.channelCount
    ) {
      constraints.audio.channelCount = audioConstraints.channelCount;
    }

    // In case we don't have a videoinput device but specify
    // video: true, we get NotFoundError in FF & DevicesNotFoundError in
    // Chrome.
    if (devices.filter(device => device.kind === 'videoinput').length === 0) {
      constraints.video = false;
    }

    DeviceManager.applyLastUsedDevices(constraints, devices);
    DeviceManager.applyWidescreen(constraints);

    return constraints;
  }
  /* eslint-enable max-statements, complexity */

  static applyLastUsedDevices(constraints, devices) {
    const videoId = LocalStorage.load('videoId');
    const audioId = LocalStorage.load('audioId');
    if (
      constraints.video === true &&
      videoId &&
      devices.find(device => device.deviceId === videoId)
    ) {
      constraints.video = { deviceId: { exact: videoId } };
    }
    if (
      constraints.audio &&
      audioId &&
      devices.find(device => device.deviceId === audioId)
    ) {
      constraints.audio.deviceId = { exact: audioId };
    }
  }

  static applyWidescreen(constraints) {
    const { widescreen } = eyesonOptions;
    if (widescreen) {
      if (constraints.video === true) {
        constraints.video = {};
      }
      if (constraints.video) {
        Object.assign(constraints.video, { width: 640, height: 360 });
      }
    } else if (
      typeof constraints.video === 'object' &&
      ('width' in constraints.video || 'height' in constraints.video)
    ) {
      Reflect.deleteProperty(constraints.video, 'width');
      Reflect.deleteProperty(constraints.video, 'height');
    }
  }

  /**
   * For now we handle the mobile options and constraints completely separately.
   * That way we can address the facingMode constraint.
   **/
  static getMobileConstraints(options) {
    let constraints = Object.assign({ audio: true, video: true }, options);
    DeviceManager.applyWidescreen(constraints);
    return Promise.resolve(constraints);
  }

  /**
   * Get sinkId.
   **/
  static getSinkId() {
    let sinkId = LocalStorage.load('sinkId', '');
    return DeviceManager.getDevices().then(devices => {
      if (!devices.find(device => device.deviceId === sinkId)) {
        sinkId = 'default';
      }
      return sinkId;
    });
  }

  /**
   * Return available devices in a friendly format.
   **/
  static fetchDevices() {
    return DeviceManager.getDevices().then(devices => {
      const speakers = devices.filter(device => device.kind === 'audiooutput');
      if (
        speakers.length > 0 &&
        speakers.findIndex(speaker => speaker.deviceId === 'default') === -1
      ) {
        speakers.unshift({
          deviceId: 'default',
          kind: 'audiooutput',
          groupId: 'default',
          label: 'System default'
        });
      }
      return {
        cameras: devices.filter(device => device.kind === 'videoinput'),
        microphones: devices.filter(device => device.kind === 'audioinput'),
        speakers: speakers
      };
    });
  }

  static fetchInputDevices() {
    return DeviceManager.getDevices().then(devices => {
      return devices.filter(device => device.kind.includes('input'));
    });
  }

  async setVirtualBackgroundType(type) {
    if (!VirtualBackgroundMixer.isTypeAllowed(type)) {
      Logger.warn(
        'DeviceManage::setVirtualBackgroundType type not allowed',
        type
      );
      return;
    }
    await VirtualBackgroundMixer.checkExternalImage(type);
    const wasOff = this.virtualBackgroundType === 'off';
    const willBeOff = type === 'off';
    this.virtualBackgroundType = type;
    this.virtualBackground.changeBackground(type);
    if (this.stream && ((wasOff && !willBeOff) || (!wasOff && willBeOff))) {
      this.update();
    }
  }

  static getStoredVirtualBackgroundType(isBlobAvailable) {
    const type = LocalStorage.load('virtualBackgroundType', 'off');
    if (type === 'image:blob') {
      return VirtualBackgroundMixer.getImageBlobOrFallback(isBlobAvailable);
    }
    return type;
  }

  loadLocalImageForVirtualBackground(callbackFN) {
    VirtualBackgroundMixer.loadLocalImage(error => {
      if (!error) {
        this.setVirtualBackgroundType('image:blob');
      }
      callbackFN(error);
    });
  }

  /**
   * Sets cameras, microphones, and speakers and starts a video stream
   * with supplied options.
   **/
  start() {
    this.watchForNewDevices();

    return DeviceManager.fetchDevices()
      .then(devices => this.setDevices(devices))
      .then(() => DeviceManager.getConstraints(this.options))
      .then(constraints => {
        this.constraints = constraints;
        return navigator.mediaDevices.getUserMedia({
          video: this.options.eco ? false : this.constraints.video,
          audio: this.constraints.audio || true
        });
      })
      .then(this.adjustAudioTrack)
      .then(this.initiateVirtualBackground)
      .then(this.setStream)
      .catch(this.handleError);
  }

  stopStream() {
    if (!this.stream) {
      return;
    }
    if (this.virtualBackground.originalStream) {
      this.virtualBackground.stopOriginalStream();
      this.virtualBackground.terminate();
    } else {
      stopStream(this.stream);
    }
    this.stream = null;
  }

  /**
   * Stop the media stream. Since we call getUserMedia on gotDevices we don't
   * have a stream in that case.
   **/
  stop() {
    this.stopStream();
  }

  terminate() {
    navigator.mediaDevices.ondevicechange = null;
    this.terminationInProgress = true;
    window.setTimeout(() => {
      this.stop();
      this.virtualBackground.destroy();
    }, 10);
  }

  setDevices(devices) {
    const camHasChanged = changedDevices(this.cameras, devices.cameras);
    const micHasChanged = changedDevices(this.microphones, devices.microphones);
    const speakerHasChanged = changedDevices(this.speakers, devices.speakers);
    this.cameras = devices.cameras;
    this.microphones = devices.microphones;
    this.speakers = devices.speakers;
    this.options.audio =
      this.microphones.length > 0 ? this.options.audio : false;
    this.options.video = this.cameras.length > 0 ? this.options.video : false;
    if (camHasChanged || micHasChanged || speakerHasChanged) {
      this.emit(devices);
    }
  }

  watchForNewDevices() {
    navigator.mediaDevices.ondevicechange = () => {
      DeviceManager.fetchDevices()
        .then(devices => this.setDevices(devices))
        .catch(this.handleError);
    };
  }

  /**
   * Register listeners for changes on devices.
   **/
  onChange(callback) {
    this.listeners.push(callback);
  }

  /**
   * Remove a listener.
   **/
  removeListener(callback) {
    this.listeners = this.listeners.filter(listener => listener !== callback);
  }

  /**
   * Wrapper around mediaDevices getUserMedia. Ensures a running stream is
   * stopped and a new one started with newly defined constraints.
   **/
  update(constraints) {
    this.constraints = constraints || this.constraints;
    this.stop();

    this.watchForNewDevices();

    return navigator.mediaDevices
      .getUserMedia(this.constraints)
      .then(this.initiateVirtualBackground)
      .then(this.setStream)
      .catch(this.handleError);
  }

  /**
   * Similar to update but instead of directly supplying constraints,
   * update with options.
   **/
  updateWithOptions(options, isMobile = false) {
    this.options = options || this.options;
    this.stop();

    this.watchForNewDevices();

    const constraintsFn = isMobile ? 'getMobileConstraints' : 'getConstraints';
    return DeviceManager[constraintsFn](this.options)
      .then(constraints => {
        this.constraints = constraints;
        return navigator.mediaDevices.getUserMedia({
          video: this.options.eco ? false : this.constraints.video,
          audio: this.constraints.audio || true
        });
      })
      .then(this.adjustAudioTrack)
      .then(this.initiateVirtualBackground)
      .then(this.setStream)
      .catch(this.handleError);
  }

  adjustAudioTrack(stream) {
    if (stream.getAudioTracks().length === 1) {
      stream.getAudioTracks()[0].enabled = this.options.audio;
    }
    return stream;
  }

  initiateVirtualBackground(stream) {
    if (
      this.virtualBackgroundType !== 'off' &&
      stream &&
      stream.getVideoTracks().length === 1
    ) {
      return this.virtualBackground.initiateStream(stream);
    }
    return stream;
  }

  // eslint-disable-next-line max-statements
  storeConstraints() {
    const { constraints } = this;
    const { video, audio } = constraints;
    Logger.debug('DeviceManager::storeConstraints', constraints);
    LocalStorage.store('mediaConstraints', constraints);
    LocalStorage.store('sinkId', this.sinkId);
    LocalStorage.store('virtualBackgroundType', this.virtualBackgroundType);
    if (video && video.deviceId && video.deviceId.exact) {
      LocalStorage.store('videoId', video.deviceId.exact);
    }
    if (audio && audio.deviceId && audio.deviceId.exact) {
      LocalStorage.store('audioId', audio.deviceId.exact);
    }
    this.virtualBackground.updateCache(this.virtualBackgroundType);
  }

  /**
   * Set active stream.
   *
   * NOTE: This can be called _after_ we have already stopped.
   **/
  // eslint-disable-next-line max-statements
  setStream(stream) {
    const firstRun = this.stream === null;
    this.stream = stream;
    if (this.terminationInProgress) {
      this.stop();
      return Promise.resolve();
    }
    const emitStreamUpdate = () => {
      this.emit({
        stream: this.stream,
        constraints: this.constraints,
        options: this.options
      });
      this.verifyStream();
    };
    if (firstRun) {
      return DeviceManager.fetchDevices().then(devices => {
        this.setDevices(devices);
        emitStreamUpdate();
      });
    }
    emitStreamUpdate();
    return Promise.resolve();
  }

  verifyStream() {
    if (
      this.constraints.video &&
      this.stream &&
      this.stream.getVideoTracks().length === 0 &&
      !this.options.eco
    ) {
      this.handleError({ name: 'EyesonCameraError' });
    }

    if (
      this.constraints.audio &&
      this.stream &&
      this.stream.getAudioTracks().length === 0
    ) {
      this.handleError({ name: 'EyesonMicrophoneError' });
    }
  }

  /**
   * Set video input selection.
   **/
  setVideoInput(deviceId) {
    let videoConstraints = {};
    Object.assign(videoConstraints, this.constraints.video, {
      deviceId: { exact: deviceId }
    });
    this.constraints.video = videoConstraints;
    return this.update();
  }

  /**
   * Set audio input selection.
   **/
  setAudioInput(deviceId, options = {}) {
    let audioConstraints = {};
    Object.assign(audioConstraints, this.constraints.audio, {
      deviceId: { exact: deviceId }
    });
    this.constraints.audio = audioConstraints;
    if (options.preventUpdate === true) {
      return Promise.resolve();
    }
    return this.update();
  }

  /**
   * Set and store audio output selection.
   **/
  setAudioOutput(sinkId) {
    this.sinkId = sinkId || 'default';
    LocalStorage.store('sinkId', this.sinkId);
    this.emit({ sinkId: this.sinkId });
  }

  handleError(error) {
    Logger.error('DeviceManager::', error);
    this.emit({ error: error, constraints: this.constraints });
  }

  /**
   * emit bound listeners about changes.
   **/
  emit(state) {
    this.listeners.forEach(listener => listener(state));
  }
}

export default DeviceManager;
/* eslint-enable max-lines */
