import config from './config.js';
import options from './options.js';
import ComApi from './ComApi.js';
import Logger from './Logger.js';
import throttle from './utils/throttle.js';
import EventHandler from './EventHandler.js';
import ConferenceSession from './ConferenceSession.js';
import ConnectionMonitor from './ConnectionMonitor.js';
import ActionCableConnection from './ActionCableConnection.js';

/**
 * Initial connection status change updater.
 **/
const updateStatus = (eyeson, status) =>
  eyeson.core.eventHandler.send({
    type: 'connection',
    connectionStatus: status
  });

const keepRoomAlive = eyeson => {
  eyeson.core.keepRoomAlive = setInterval(() => {
    eyeson.core.rtConnection.send({ type: 'user_joins' });
  }, 30000);
};

/**
 * Remove this once WSS messages arrive reliably.
 **/
const pollingFallback = eyeson => {
  let counter = 1;
  eyeson.core.pollingFallbackInterval = setInterval(() => {
    const { core } = eyeson;
    if (counter === 200) {
      Logger.debug(
        'eyeson::pollingFallback: max count exceeded, clearing interval.'
      );
      clearInterval(core.pollingFallbackInterval);
      return;
    }

    if (core.eventHandler._connection) {
      Logger.debug(
        'eyeson::pollingFallback: connection set, clearing interval.'
      );
      clearInterval(core.pollingFallbackInterval);
      return;
    }

    core.comApi.getRoom(data => {
      if (data.ready === true) {
        Logger.debug('eyeson::pollingFallback: room ready');
        core.eventHandler.send({ type: 'room_ready', content: data });
        return;
      }
      Logger.debug('eyeson::pollingFallback: room not ready', counter);
      counter += 1;
    });
  }, 5000);
};

/**
 * Load initial room data.
 **/
const loadInitialInfos = eyeson => {
  const { core } = eyeson;
  const { broadcasts } = core.eventHandler._rtData;
  if (broadcasts) {
    core.eventHandler.send({
      type: 'broadcasts_update',
      broadcasts: broadcasts
    });
  }
};

/**
 * Join a session and listen to any events. eventHandler keeps all the
 * stuff.
 **/
// eslint-disable-next-line max-statements
const joinSession = (eyeson, mediaOptions) => {
  const { core } = eyeson;
  if (!core.eventHandler._connection) {
    Logger.error(
      'You tried to join a session that is not yet available. ' +
        'Before calling join, a connection status of connected has ' +
        'to be received.'
    );
    return;
  }

  const session = new ConferenceSession(
    core.eventHandler._connection,
    core.comApi,
    mediaOptions
  );
  session.setMonitor(core.eventHandler.monitor);
  core.eventHandler.session = session;

  session.start();
  loadInitialInfos(eyeson);
  clearInterval(core.keepRoomAlive);
  eyeson.session = session;
};

/**
 * Initialise our connections.
 **/
const prepareConnection = eyeson => {
  const { core } = eyeson;
  updateStatus(eyeson, 'fetch_room');

  core.eventHandler.eyeson = eyeson;

  core.comApi.onError(() =>
    core.eventHandler.send({ type: 'warning', name: 'error:comapi' })
  );

  // eslint-disable-next-line max-statements
  core.comApi.getRoom(data => {
    if (data.error) {
      Logger.warn('eyeson::prepareConnection', data.error);
      updateStatus(eyeson, 'access_denied');
      return;
    }
    updateStatus(eyeson, 'received_room');

    core.rtConnection = new ActionCableConnection(data.links.websocket);
    core.eventHandler.rtConnection = core.rtConnection;
    core.rtConnection.startSession();

    core.eventHandler.monitor = new ConnectionMonitor();
    core.eventHandler.api = core.comApi;
    keepRoomAlive(eyeson);
    pollingFallback(eyeson);
  });
};

/****** The following represents the public API, adapt with caution! *********/
class eyesonClass {
  /****** Public data ********************************************************/
  constructor() {
    /**
     * The room, user and links to be updated when fetched from the ComAPI.
     */
    this.config = config;
    this.core = { eventHandler: new EventHandler() };
    this.room = {};
    this.user = {};
    this.links = {};
    this.options = options;
  }
  /****** Public helper methods **********************************************/

  /**
   * Attach event listener
   **/
  onEvent(listener) {
    if (typeof listener !== 'function') {
      Logger.error(
        'A listener to eyeson events has to be of type function.' +
          ' The argument passed to onEvent is of type ' +
          typeof listener +
          '.'
      );
      return;
    }
    this.core.eventHandler.onReceive(listener);
  }

  /**
   * Remove event listener
   **/
  offEvent(listener) {
    this.core.eventHandler.removeListener(listener);
  }

  /**
   * Prepare required core connections.
   **/
  connect(token) {
    Logger.debug('eyeson::connect', token);
    this.core.comApi = new ComApi(this.config.api, token);
    prepareConnection(this);
  }

  /**
   * Join a session with supplied mediaOptions (audio/video).
   **/
  join(mediaOptions) {
    Logger.debug('eyeson::join', mediaOptions);
    joinSession(this, mediaOptions);
  }

  /**
   * Start an eyeson room meeting.
   **/
  start(token, mediaOptions = { audio: true, video: true }) {
    Logger.debug('eyeson::start');
    const joinOnConnect = event => {
      if (event.connectionStatus !== 'ready') {
        return;
      }
      this.offEvent(joinOnConnect);
      this.join(mediaOptions);
    };
    this.onEvent(joinOnConnect);
    this.connect(token);
  }

  /**
   * Destroy and cleanup a session.
   **/
  destroy() {
    const { core } = this;
    Logger.debug('eyeson::destroy');
    clearInterval(core.keepRoomAlive);
    clearInterval(core.pollingFallbackInterval);
    core.eventHandler.destroy();
    core.eventHandler = new EventHandler();
  }

  /**
   * Receive an event from client.
   **/
  send(msg) {
    msg._src = 'client';
    return this.core.eventHandler.send(msg);
  }

  /**
   * When invoked repeatedly, will only actually call the original function at
   * most once per every wait milliseconds.
   **/
  throttledSend(msg) {
    if (!this._throttledSend) {
      this._throttledSend = throttle(message => this.send(message), 500);
    }

    return this._throttledSend(msg);
  }

  /**
   * Create a new instance
   */
  createInstance() {
    // eslint-disable-next-line new-cap
    return new eyesonClass();
  }
}

// eslint-disable-next-line new-cap
const instance = new eyesonClass();

export default instance;
